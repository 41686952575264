import React, { lazy } from 'react';
import { Routes, Route } from 'react-router-dom';

const FarmhubRegistration = lazy(() => import('../../container/farmhub/addFarmhub'));

function FarmRoute() {
  return (
    <Routes>
      <Route path="add" element={<FarmhubRegistration />} />
      {/* <Route path="list" element={<FarmList />} />
      <Route path="update/:farmhubId" element={<FarmUpdate />} />
      <Route path="view/:farmhubId" element={<FarmDetails />} /> */}
    </Routes>
  );
}

export default FarmRoute;
