import React from 'react';
import { Row, Col } from 'antd';
import propTypes from 'prop-types';
import { UilUserCircle, UilPhone, UilMapMarker } from '@iconscout/react-unicons';
import styled from 'styled-components';

import { OverviewDataStyleWrap } from './Style2';

import OverviewCard from './OverviewCard';

const data = [
  {
    id: 1,
    type: 'primary',
    icon: 'land-icon.svg',
    label: 'Field Size',
    title: '2 ha',
  },
  {
    id: 2,
    type: 'info',
    icon: 'business.svg',
    label: 'Target Yield',
    title: '20,000kg',
  },
  {
    id: 3,
    type: 'secondary',
    icon: 'crop.svg',
    label: 'Crop Planned',
    title: 'Maize',
  },
  {
    id: 4,
    type: 'warning',
    icon: 'soil.svg',
    label: 'Soil texture',
    title: 'Loamy',
  },
  {
    id: 1,
    type: 'primary',
    icon: 'potassium.svg',
    label: 'potassium (K)ppm',
    title: '20.0',
  },
  {
    id: 1,
    type: 'primary',
    icon: 'nitrogen-icon.svg',
    label: 'Nitrogen (N)ppm',
    title: '20.0',
  },
  {
    id: 1,
    type: 'primary',
    icon: 'phosphorus.svg',
    label: 'Phosphorus (P)ppm',
    title: '20.0',
  },
  {
    id: 1,
    type: 'primary',
    icon: 'water-drop.svg',
    label: 'pH ',
    title: '6.0',
  },
];
const OverviewDataList = React.memo(({ column }) => {
  return (
    <FarmerDetailsBox>
      <OverviewDataStyleWrap>
        <Farmer>
          <div className="">
            <center>
              <h3>Farmer information</h3>
            </center>
            <div className="general-info">
              <p>
                <span>
                  <UilUserCircle />
                </span>
                John Doe
              </p>
              <p>
                <span>
                  <UilPhone />
                </span>
                0700183737
              </p>
              <p>
                <span>
                  <UilMapMarker />
                </span>
                St. XYZ
              </p>
            </div>
          </div>
        </Farmer>
        <Row gutter={25}>
          {data.map((item, i) => {
            return (
              <Col xxl={column === '2' ? null : 6} md={6} xs={12} key={i}>
                <OverviewCard data={item} contentFirst />
              </Col>
            );
          })}
        </Row>
      </OverviewDataStyleWrap>
    </FarmerDetailsBox>
  );
});

OverviewDataList.propTypes = {
  column: propTypes.string,
};

OverviewDataList.defaultProps = {
  column: '2',
};

export default OverviewDataList;
const FarmerDetailsBox = styled.div`
  background: ${({ theme }) => theme[theme.mainContent]['main-background-light']};
  padding: 10px;
`;
const Farmer = styled.div`
  h1 {
    font-size: 20px;
    text-align: center;
  }
  .general-info {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 25px;
    p {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      gap: 5px;
    }
  }
`;
