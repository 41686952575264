import { Card } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { ReactSVG } from 'react-svg';
import styled from 'styled-components';

function OverviewCard({ data, className, contentFirst, halfCircleIcon }) {
  const { type, icon, label, title } = data;

  return (
    <StyledCard bordered={false} className={className} halfCircleIcon={halfCircleIcon}>
      <div className={`overview-card overview-card-${type}`}>
        <div className={`overview-card__top ${contentFirst ? 'flex-row-reverse' : 'flex-row'}`}>
          {/* Hide the icon in mobile and tablet views */}
          <div className={`overview-card__icon ${type} hide-on-mobile`}>
            <ReactSVG src={require(`../../static/img/icon/${icon}`)} />
          </div>
          <div className={`overview-card__content ${contentFirst ? 'text-right' : ''}`}>
            {halfCircleIcon ? (
              <>
                <span className="overview-label">{label}</span>
                <h4 className="overview-total">{title}</h4>
              </>
            ) : (
              <>
                <h4 className="overview-total">{title}</h4>
                <span className="overview-label">{label}</span>
              </>
            )}
          </div>
        </div>
      </div>
    </StyledCard>
  );
}

OverviewCard.defaultProps = {
  data: {},
  className: 'overview-card-box',
  contentFirst: false,
  halfCircleIcon: false,
};

OverviewCard.propTypes = {
  data: PropTypes.object,
  className: PropTypes.string,
  contentFirst: PropTypes.bool,
  halfCircleIcon: PropTypes.bool,
};

export default OverviewCard;

const StyledCard = styled(Card)`
  .overview-card {
    padding: 20px;
    border-radius: 10px;
    background-color: ${({ theme }) => theme[theme.mainContent]['white-background']};
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);

    &-primary {
      border-top: 4px solid #3498db;
    }

    &-info {
      border-top: 4px solid #2ecc71;
    }

    &-secondary {
      border-top: 4px solid #f39c12;
    }

    &-warning {
      border-top: 4px solid #e74c3c;
    }

    &__top {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &__icon {
      flex: 0 0 auto;
      margin-right: 20px;
      svg {
        width: 30px;
        height: 30px;
        fill: ${({ theme }) => theme[theme.mainContent]['primary-text']};
      }
    }

    &__content {
      flex: 1 1 auto;
    }

    .overview-label {
      font-size: 14px;
      color: ${({ theme }) => theme[theme.mainContent]['secondary-text']};
    }

    .overview-total {
      font-size: 18px;
      font-weight: bold;
      color: ${({ theme }) => theme[theme.mainContent]['primary-text']};
    }

    &.flex-row-reverse {
      flex-direction: row-reverse;
    }

    &.text-right {
      text-align: right;
    }

    @media (max-width: 768px) {
      .hide-on-mobile {
        display: none;
      }
    }
  }
`;
