import React, { lazy } from 'react';
import { Routes, Route } from 'react-router-dom';

const FarmRegistration = lazy(() => import('../../container/farm/farmRegistration'));
const FarmList = lazy(() => import('../../container/farm/farmList'));
const FarmUpdate = lazy(() => import('../../container/farm/farmUpdate'));
// const FarmDetails = lazy(() => import('../../container/farm/farmDetails'));
const FarmDetails = lazy(() => import('../../container/farm/FarmDetail/farmDetails'));

function FarmRoute() {
  return (
    <Routes>
      <Route path="registration" element={<FarmRegistration />} />
      <Route path="list" element={<FarmList />} />
      <Route path="update/:farmId" element={<FarmUpdate />} />
      <Route path="details/:farmId" element={<FarmDetails />} />
      {/* <Route path="details/:farmerId" element={<FarmView />} /> */}
    </Routes>
  );
}

export default FarmRoute;
