import React, { lazy } from 'react';
import { Routes, Route } from 'react-router-dom';

const AddStock = lazy(() => import('../../container/stock/addStock'));

function FarmRoute() {
  return (
    <Routes>
      <Route path="add" element={<AddStock />} />
      {/* <Route path="list" element={<FarmList />} />
      <Route path="update/:farmhubId" element={<FarmUpdate />} />
      <Route path="view/:farmhubId" element={<FarmDetails />} /> */}
    </Routes>
  );
}

export default FarmRoute;
