import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Report from '../../container/report/Report';

function ReportPage() {
  return (
    <Routes>
      <Route path="generate" element={<Report />} />
    </Routes>
  );
}

export default ReportPage;
