import { UilCircle } from '@iconscout/react-unicons';

import { Menu } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';

import UilEllipsisV from '@iconscout/react-unicons/icons/uil-ellipsis-v';
import UilCreateDashboard from '@iconscout/react-unicons/icons/uil-create-dashboard';
import propTypes from 'prop-types';

function MenuItems({ toggleCollapsed }) {
  const { t } = useTranslation();

  function getItem(label, key, icon, children, type) {
    return {
      key,
      icon,
      children,
      label,
      type,
    };
  }

  const { topMenu } = useSelector((state) => {
    return {
      topMenu: state.ChangeLayoutMode.topMenu,
    };
  });

  const path = '/admin';

  const pathName = window.location.pathname;
  const pathArray = pathName.split(path);
  const mainPath = pathArray[1];
  const mainPathSplit = mainPath.split('/');

  const [openKeys, setOpenKeys] = React.useState(
    !topMenu ? [`${mainPathSplit.length > 2 ? mainPathSplit[1] : 'dashboard'}`] : [],
  );

  const onOpenChange = (keys) => {
    setOpenKeys(keys[keys.length - 1] !== 'recharts' ? [keys.length && keys[keys.length - 1]] : keys);
  };

  const onClick = (item) => {
    if (item.keyPath.length === 1) setOpenKeys([]);
  };

  const items = [
    getItem(
      <NavLink onClick={toggleCollapsed} to={`${path}/pages/starter`}>
        {t('Shamba Assistant')} {t('Home')}
      </NavLink>,
      'starter',
      !topMenu && (
        <NavLink className="menuItem-iocn" to={`${path}/pages/starter`}>
          <UilCircle />
        </NavLink>
      ),
    ),
    getItem(t('FarmHub'), 'FarmHub Menu', !topMenu && <UilCreateDashboard />, [
      getItem(
        <NavLink onClick={toggleCollapsed} to={`${path}/farmhub/add`}>
          {t('FarmHub Registration')}
        </NavLink>, // Menu Title
        '11', // Menu ID
      ),
      getItem(
        <NavLink onClick={toggleCollapsed} to={`${path}/farmhub/list`}>
          {t('FarmHub List')}
        </NavLink>, // Menu Title
        '12', // Menu ID
      ),
    ]),
    getItem(t('Farmers'), 'Farmers Menu', !topMenu && <UilCreateDashboard />, [
      getItem(
        <NavLink onClick={toggleCollapsed} to={`${path}/farmer/registration`}>
          {t('Farmers Registration')}
        </NavLink>, // Menu Title
        '1', // Menu ID
      ),
      getItem(
        <NavLink onClick={toggleCollapsed} to={`${path}/farmer/list`}>
          {t('Farmers List')}
        </NavLink>, // Menu Title
        '2', // Menu ID
      ),
    ]),
    getItem(t('Farm'), 'Farm Menu', !topMenu && <UilCreateDashboard />, [
      getItem(
        <NavLink onClick={toggleCollapsed} to={`${path}/farm/registration`}>
          {t('Farm Registration')}
        </NavLink>, // Menu Title
        '4', // Menu ID
      ),
      getItem(
        <NavLink onClick={toggleCollapsed} to={`${path}/farm/list`}>
          {t('Farm List')}
        </NavLink>, // Menu Title
        '5', // Menu ID
      ),
    ]),
    getItem(t('Soil Test'), 'SoilTest Menu', !topMenu && <UilCreateDashboard />, [
      getItem(
        <NavLink onClick={toggleCollapsed} to={`${path}/soil/test-result-list`}>
          {t('Soil Test')}
        </NavLink>, // Menu Title
        '51', // Menu ID
      ),
      getItem(
        <NavLink onClick={toggleCollapsed} to={`${path}/soil-agent-test`}>
          {t('Agent')}
        </NavLink>, // Menu Title
        '52', // Menu ID
      ),
    ]),
    getItem(t('Report'), 'Report Menu', !topMenu && <UilCreateDashboard />, [
      getItem(
        <NavLink onClick={toggleCollapsed} to={`${path}/report/generate`}>
          {t('Generate')}
        </NavLink>, // Menu Title
        '51', // Menu ID
      ),
    ]),
  ];

  return (
    <Menu
      onOpenChange={onOpenChange}
      onClick={onClick}
      mode={!topMenu || window.innerWidth <= 991 ? 'inline' : 'horizontal'}
      // // eslint-disable-next-line no-nested-ternary
      defaultSelectedKeys={
        !topMenu
          ? [
              `${
                mainPathSplit.length === 1 ? 'home' : mainPathSplit.length === 2 ? mainPathSplit[1] : mainPathSplit[2]
              }`,
            ]
          : []
      }
      defaultOpenKeys={!topMenu ? [`${mainPathSplit.length > 2 ? mainPathSplit[1] : 'dashboard'}`] : []}
      overflowedIndicator={<UilEllipsisV />}
      openKeys={openKeys}
      items={items}
    />
  );
}

MenuItems.propTypes = {
  toggleCollapsed: propTypes.func,
};

export default MenuItems;
