import React from 'react';
import { Table, Typography, Space } from 'antd';
import { EnvironmentOutlined, WeiboSquareOutlined, ToolOutlined } from '@ant-design/icons';

const { Text } = Typography;

const soilCorrectionData = [
  {
    key: '1',
    activity: 'Before Planting',
    instructions: 'If Available 1049 kg Compost or Animal Manure',
    bestOption: 'Use 1049 kg of compost',
    firstAlternative: 'Animal manure',
    secondAlternative: 'Commercial organic fertilizer',
    icon: <EnvironmentOutlined style={{ color: '#2ecc71' }} />,
  },
  {
    key: '2',
    activity: 'At Planting',
    instructions:
      'Place the fertilizer at the bottom of the planting holes, put 10 cm of soil on top, add the seed and cover the seed with soil.',
    bestOption: 'Use recommended fertilizer mix',
    firstAlternative: 'Use a balanced NPK fertilizer',
    secondAlternative: 'Use organic starter fertilizer',
    icon: <WeiboSquareOutlined style={{ color: '#3498db' }} />,
  },
  {
    key: '3',
    activity: '6 weeks after',
    instructions: 'You can topdress when your crops are 6 weeks old and healthy (no pests, sufficient rain).',
    bestOption: 'Topdress with urea',
    firstAlternative: 'Topdress with ammonium nitrate',
    secondAlternative: 'Topdress with compost tea',
    icon: <ToolOutlined style={{ color: '#f39c12' }} />,
  },
];
const getIcon = (activity) => {
  switch (activity) {
    case 'Before Planting':
      return <EnvironmentOutlined style={{ color: '#2ecc71' }} />;
    case 'At Planting':
      return <WeiboSquareOutlined style={{ color: '#3498db' }} />;
    case '6 weeks after':
      return <ToolOutlined style={{ color: '#f39c12' }} />;
    default:
      return null;
  }
};

const columns = [
  {
    title: 'Activity',
    dataIndex: 'activity',
    key: 'activity',
    render: (text, record) => (
      <Space>
        {getIcon(record.activity)}
        <Text strong>{text}</Text>
      </Space>
    ),
    width: '20%',
  },
  {
    title: 'Instructions',
    dataIndex: 'instructions',
    key: 'instructions',
    width: '30%',
  },
  {
    title: 'Best Option',
    dataIndex: 'bestOption',
    key: 'bestOption',
    width: '20%',
  },
  {
    title: 'First Alternative',
    dataIndex: 'firstAlternative',
    key: 'firstAlternative',
    width: '15%',
  },
  {
    title: 'Second Alternative',
    dataIndex: 'secondAlternative',
    key: 'secondAlternative',
    width: '15%',
  },
];

function SoilCorrectionPlan2() {
  return (
    <Table
      columns={columns}
      dataSource={soilCorrectionData}
      title={() => 'Soil Correction Plan'}
      pagination={false}
      bordered
      scroll={{ x: 800 }}
      rowClassName={(record) => (record.index % 2 === 0 ? 'even-row' : 'odd-row')}
      style={{
        tbody: {
          tr: {
            ':nth-child(even)': { backgroundColor: '#d4edda' },
            ':nth-child(odd)': { backgroundColor: '#f8d7da' },
          },
        },
      }}
    />
  );
}

export default SoilCorrectionPlan2;
