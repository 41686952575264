import React from 'react';
import { Card, Typography } from 'antd';

const { Title } = Typography;

export function Recommandation() {
  return (
    <Card title="" style={{ width: '100%' }}>
      <Title level={3}>Soil Testing Report Recommendations</Title>
      <p>We&apos; re pleased to share the recent soil test results for your farm. Here&apos;s a summary:</p>
      <h2>1. Nutrient Levels</h2>
      <p>
        Nitrogen (N): Optimal levels. Monitor and apply nitrogen-based fertilizers as needed. Phosphorus (P): Slightly
        below optimal. Apply phosphorus-rich fertilizers before planting. Potassium (K): Adequate. Consider
        potassium-rich fertilizers for optimal soil fertility.
      </p>
      <h3>2. pH Levels</h3>
      <p>Soil pH is ideal. Monitor regularly and use lime or sulfur-based amendments if pH fluctuates.</p>
      <h3>3. Soil Amendments</h3>
      <p>
        Incorporate organic matter yearly for improved soil health. Promote microbial activity through practices like
        crop rotation.
      </p>
      <h3>4. Crop-Specific Recommendations</h3>
      <p>Choose crop varieties suited to your soil conditions and practice crop rotation.</p>
      <h3>5. Monitoring and Follow-Up</h3>
      <p>Regularly monitor soil nutrient levels, pH, and crop performance. Adjust practices accordingly.</p>
      <h3>Conclusion</h3>
      <p>We hope these recommendations enhance your farm&apos;s productivity. Contact us for further assistance.</p>
    </Card>
  );
}
