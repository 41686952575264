import React from 'react';
import { Row, Col, Card, Typography } from 'antd';
import { EnvironmentOutlined, WeiboSquareOutlined, ToolOutlined } from '@ant-design/icons';

const { Title, Text } = Typography;

const soilCorrectionData = [
  {
    key: '1',
    title: 'Before Planting',
    description: 'If Available 1049 kg Compost or Animal Manure',
    options: ['Use 1049 kg of compost', 'Animal manure', 'Commercial organic fertilizer'],
    icon: <EnvironmentOutlined style={{ fontSize: '24px', color: '#2ecc71' }} />,
  },
  {
    key: '2',
    title: 'At Planting',
    description:
      'Place the fertilizer at the bottom of the planting holes, put 10 cm of soil on top, add the seed and cover the seed with soil.',
    options: ['Use recommended fertilizer mix', 'Use a balanced NPK fertilizer', 'Use organic starter fertilizer'],
    icon: <WeiboSquareOutlined style={{ fontSize: '24px', color: '#3498db' }} />,
  },
  {
    key: '3',
    title: '6 weeks after',
    description: 'You can topdress when your crops are 6 weeks old and healthy (no pests, sufficient rain).',
    options: ['Topdress with urea', 'Topdress with ammonium nitrate', 'Topdress with compost tea'],
    icon: <ToolOutlined style={{ fontSize: '24px', color: '#f39c12' }} />,
  },
];

function SoilCorrectionPlan1() {
  return (
    <div style={{ padding: '20px' }}>
      <center>
        <Title level={3}>Soil Correction Plan</Title>
      </center>
      <Row gutter={[16, 16]} justify="center">
        {soilCorrectionData.map((step) => (
          <Col xs={24} sm={12} md={8} key={step.key}>
            <Card
              style={{ width: '100%', textAlign: 'left' }}
              title={
                <>
                  {step.icon}
                  <Text strong style={{ marginLeft: '8px' }}>
                    {step.title}
                  </Text>
                </>
              }
            >
              <Text>{step.description}</Text>
              <ul>
                {step.options.map((option, index) => (
                  <li key={index}>{option}</li>
                ))}
              </ul>
            </Card>
          </Col>
        ))}
      </Row>
    </div>
  );
}

export default SoilCorrectionPlan1;
