import { useState, useRef } from 'react';
import { Col, Row, Spin, notification } from 'antd';
import styled from 'styled-components';
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import ReportHeader from './ReportHeader';
import { Recommandation } from './Recommandation';
import OverviewDataList from './OverviewDataList';
import SoilCorrectionPlan1 from './CorrectionPlan1';
import SoilCorrectionPlan2 from './CorrectionPlan2';
import { Cards } from '../../components/cards/frame/cards-frame';
import { PageHeader } from '../../components/page-headers/page-headers';
import { Main } from '../styled';

function Report() {
  const [loading, setLoading] = useState(false);
  const [isGeneratingPDF, setIsGeneratingPDF] = useState(false);
  const targetRef = useRef();

  const openNotification = (type, message, description) => {
    notification[type]({
      message,
      description,
      placement: 'topRight',
    });
  };

  const downloadPDF = async () => {
    setLoading(true);
    setIsGeneratingPDF(true);
    openNotification('info', 'Download Started', 'Generating your PDF report...');
    const element = targetRef.current;
    // eslint-disable-next-line new-cap
    const pdf = new jsPDF('p', 'pt', 'a4');
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = pdf.internal.pageSize.getHeight();
    const padding = 25;

    try {
      const canvas = await html2canvas(element, { scrollY: -window.scrollY });
      const imgData = canvas.toDataURL('image/png');
      const imgProps = pdf.getImageProperties(imgData);
      const imgHeight = (imgProps.height * pdfWidth) / imgProps.width;
      const totalPages = Math.ceil(imgHeight / (pdfHeight - padding * 2));

      let yOffset = 0;

      for (let page = 0; page < totalPages; page += 1) {
        if (page > 0) {
          pdf.addPage();
          yOffset = page * (pdfHeight - padding * 2);
        }

        pdf.addImage(
          imgData,
          'PNG',
          padding,
          padding - yOffset,
          pdfWidth - padding * 2,
          (pdfWidth * imgProps.height) / imgProps.width,
        );
      }

      pdf.save('report.pdf');
      openNotification('success', 'Download Complete', 'Your PDF report has been downloaded.');
    } catch (error) {
      openNotification('error', 'Download Failed', 'There was an error generating your PDF report.');
    } finally {
      setLoading(false);
      setIsGeneratingPDF(false);
    }
  };

  return (
    <div className="invoice-area">
      <PageHeader className="ninjadash-page-header-main" title="Report" />
      <Main>
        <Row gutter={15}>
          <Col md={24}>
            <Cards headless>
              <Spin spinning={loading}>
                <StyledButton onClick={downloadPDF} disabled={loading}>
                  Download as PDF
                </StyledButton>
              </Spin>
              <div id="print-invoice-content" ref={targetRef}>
                <PrintingWrapper isGeneratingPDF={isGeneratingPDF}>
                  <ReportHeader />
                  <OverviewDataList />
                  <SoilCorrectionPlan1 />
                  <SoilCorrectionPlan2 />
                  <div style={{ marginTop: '200px' }} />
                  <Recommandation />
                  <center>
                    <p>
                      <strong>Shamba solutions original report-{new Date().getFullYear()}</strong>
                    </p>
                  </center>
                </PrintingWrapper>
              </div>
            </Cards>
          </Col>
        </Row>
      </Main>
    </div>
  );
}

export default Report;

const PrintingWrapper = styled.div`
  padding: 10px 20px;
  ${(props) =>
    props.isGeneratingPDF &&
    `
    .recommandation-margin {
      margin-top: 200px;
    }
  `}
`;

const StyledButton = styled.button`
  background-color: #4caf50; /* Green */
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 12px;
  transition:
    background-color 0.3s ease,
    transform 0.3s ease;

  &:hover {
    background-color: #45a049;
    transform: scale(1.05);
  }

  &:active {
    background-color: #3e8e41;
    transform: scale(0.95);
  }

  &:disabled {
    background-color: #9e9e9e;
    cursor: not-allowed;
  }
`;
